<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>DVR-сервера</h2>
        <el-tabs>
          <el-tab-pane label="Список">
            <search :query.sync="query" @search="search" />
            <dvr-servers-table :servers="items" />
            <el-pagination
              class="list__pagination"
              layout="prev, pager, next"
              :page-size="count"
              :total="total"
              :current-page="page"
              @current-change="changePage"
            />
          </el-tab-pane>
          <el-tab-pane label="Логи синхронизации">
            <dvr-servers-logs />
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
    <el-footer class="page__active_bar" />
  </el-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import routeList from "@/core/mixins/routeList";
import DvrServersTable from "./DvrServersTable";
import Search from "@/components/Search";
import DvrServersLogs from "./DvrServersLogs";

export default {
  name: "DvrServersList",
  components: {
    DvrServersTable,
    DvrServersLogs,
    Search,
  },
  mixins: [routeList],
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapState("dvrServers", {
      items: (state) => state.dvrServers.data,
      total: (state) => state.dvrServers.total,
      count: (state) => state.dvrServers.count,
    }),
    ...mapGetters("dvrServers", ["getQuery"]),
  },
  methods: {
    ...mapActions("dvrServers", {
      getList: "GET_DVR_SERVERS",
    }),
    ...mapMutations("dvrServers", {
      setQuery: "SET_QUERY",
    }),
  },
};
</script>

<style>
.list,
.list__pagination {
  margin: 20px 0;
}
.list__pagination {
  text-align: center;
}
.el-table th > .cell {
  word-break: break-word;
}
.link {
  line-height: 1;
  cursor: pointer;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  color: #409eff;
  text-decoration: underline;
}
</style>
