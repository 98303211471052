<template>
  <elasticTableLogs :data="data" :loading="loading" :link-keys="linkKeys" :keys="keys" @fetch="onFetchData" />
</template>

<script>
import ElasticTableLogs from "@/core/components/ElasticTableLogs/index";
import logsMixin from "@/core/components/ElasticTableLogs/logsMixin";
import { ApiService } from "@/api";

export default {
  name: "DvrServersLogs",
  components: { ElasticTableLogs },
  mixins: [logsMixin],
  data() {
    return {
      keys: [
        "id",
        "archive_size",
        "clean_archive",
        "server_cluster",
        "name",
        "server_mode",
        "nimble_version",
        "server_id",
        "server_name",
        "status",
        "max_cameras_count",
      ],
      linkKeys: {
        id: `${process.env.BASE_URL}#/`,
      },
    };
  },
  methods: {
    fetchLogs: ApiService.logs.getDvrServerLogs,
  },
}
</script>